import React, { Component } from "react"
import { Link } from "gatsby"

export default class SocialBar extends Component {
  render() {
    return (
      <nav className="social-nav">
        <a
          target="_blank"
          href="https://github.com/julientaret"
          className="github-link"
        >
          <i className="icon-github"></i>
        </a>
        <a
          target="_blank"
          href="https://twitter.com/julien_taret"
          className="twitter-link"
        >
          <i className="icon-twitter"></i>
        </a>
        <a
          target="_blank"
          href="https://www.linkedin.com/in/julien-taret-a8b220159/"
          className="linkedin-link"
        >
          <i className="icon-linkedin"></i>
        </a>
        <a
          target="_blank"
          href="https://www.facebook.com/julien.taret"
          className="facebook-link"
        >
          <i className="icon-facebook"></i>
        </a>
        <a
          target="_blank"
          href="https://www.youtube.com/channel/UCO32Dfie9eeeRGsK7EvlJ_Q"
          className="youtube-link"
        >
          <i className="icon-youtube"></i>
        </a>
      </nav>
    )
  }
}
