import React from "react"
import { graphql, StaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import SocialBar from "./SocialBar"

const HeaderBackgrounnd = ({ className }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "bg-banner-home.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      // Set ImageData.
      const imageData = data.desktop.childImageSharp.fluid
      return (
        <BackgroundImage
          Tag="div"
          className={className}
          id="banner"
          fluid={imageData}
        >
          <section className="header-content">
            <h1 className="wrap">
              Julien Taret
              <span>Intégrateur - Développeur Front-end</span>
            </h1>
            <SocialBar />
          </section>
        </BackgroundImage>
      )
    }}
  />
)

export default HeaderBackgrounnd
