import PropTypes from "prop-types"
import React from "react"
import ProfilPic from "./ProfilPic"
import Banner from "./Banner"
const Header = ({ siteTitle }) => (
  <header>
    <Banner></Banner>
    <ProfilPic />
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
