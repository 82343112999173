import React, { Component } from "react"

export default class Copyright extends Component {
  render() {
    return (
      <footer>
        <p>
          Julien Taret - ©
          2020
        </p>
      </footer>
    )
  }
}
